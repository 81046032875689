.filters-removers
{
  .filter.picto-multi
  {
    .label{
      margin-right: 5px;
    }

    .pictos{
      display: flex;
      align-items: center;
      margin-right: 8px;
    }

    .remove-button
    {
      display: flex;
      align-items: center;
      padding: 0 5px;
      border-right: solid 1px var( --color-grey2 );

      &:last-child{
        border: none;
        padding-right: 0;
      }
    }
  }
}