#filters-window > .smart-container > .smart-content-container > .smart-content
{
  width: 100%;
  height: calc( 100vh - 144px );
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0;
  
  .filters-container
  {
    position: relative;
    width: 80%;
    height: 100%;
    display: flex;
    flex-flow: column;
    row-gap: 64px;
    padding: 32px 24px;
    border-right: solid 1px white;

    .loader-container
    {
      position: absolute;
      height: 100%;
    }

    .filters-widgets-container
    {
      display: grid;
      grid-template-columns: repeat( 3, calc( 25% - 24px ) ) 25%;
      column-gap: 24px;
      row-gap: 36px;
    }
  
    .confirm-container
    {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      gap: 15px;
  
      smart-button
      {
        justify-content: center;
        font-weight: 400;

        &.empty,
        &.fill
        {
          height: 32px !important;
        }
        &.empty{
          width: 117px !important;          
        }
        &.fill{
          width: 117px !important;
        }
      }
    }
  }

  .filters-groups-container
  {
    position: relative;
    width: 20%;
    height: 100%;        
    display: flex;
    flex-flow: column;
    padding: 32px 24px;
    gap: 16px;
  }  
}

/* Maximum height */
@media ( max-height: 960px )
{
  #filters-window > .smart-container > .smart-content-container > .smart-content
  {
    height: calc( 100vh - 56px );

    .filters-container{
      row-gap: 44px;
    }
  }
}