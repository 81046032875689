.filter-container.snippets
{
  .filters
  {
    display: flex;
    flex-flow: column;
    gap: 8px;

    smart-drop-down-list#filter-snippets-drop-down-menu-multi
    { 
      .smart-drop-down-container
      {
        smart-list-item{
          .smart-list-item-label-element
          {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: flex-start;
    
            &::before{
              width: 16px;
              height: 16px;
              content: '';
              margin-right: 5px;
              background-color: var( --color-blue );
            }
          }
        }

        smart-list-item:nth-of-type(1) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/SEO.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(2) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Shopping.svg' ) left 40% / 16px 16px no-repeat;
        }
        smart-list-item:nth-of-type(3) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/PAA.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(4) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Popular Products.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(5) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Images.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(6) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Ads.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(7) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/People Also Search.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(8) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Videos.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(9) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Knowledge Panel.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(10) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Twitter.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(11) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Find Results On.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(12) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Local Results.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(13) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Recipes.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(14) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Top Stories.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(15) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Map.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(16) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Top Sights.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(17) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Featured Snippet.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(18) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Scholar.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(19) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Answer Box.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(20) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Carousel.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(21) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Local Services.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(22) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Jobs.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(23) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Questions and Answers.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(24) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Hotels Results.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(25) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Visual Stories.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(26) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Events.svg' ) left 40% / 16px 16px no-repeat;      
        }
        smart-list-item:nth-of-type(27) .smart-list-item-label-element::before
        {
          mask: url( './../../../assets/img/pictos/snippets/Google Flights.svg' ) left 40% / 16px 16px no-repeat;      
        }
      }
    }  
  }
}