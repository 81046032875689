.filter-container.dropdown
{
  smart-drop-down-list
  { 
    .smart-buttons-container
    {
      .smart-drop-down-list-selection-picture
      {
        width: 16px;
        height: 16px;
      }
    }

    .smart-drop-down-container{
      smart-list-item{
        .smart-list-item-label-element
        {
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: flex-start;
  
          &::before{
            width: 16px;
            height: 16px;
            content: '';
            margin-right: 5px;
            background-color: var( --color-blue );
          }
        }
      }
    }

    &#filter-status-dropdown-multi
    {
      smart-list-item:nth-of-type(1) .smart-list-item-label-element::before
      {
        mask: url( './../../../assets/img/pictos/keywordStatus/status-new.svg' ) left 40% / 16px 16px no-repeat;      
      }
      smart-list-item:nth-of-type(2) .smart-list-item-label-element::before
      {
        mask: url( './../../../assets/img/pictos/keywordStatus/status-gauge.svg' ) left 40% / 16px 16px no-repeat;
      }
      smart-list-item:nth-of-type(3) .smart-list-item-label-element::before
      {
        mask: url( './../../../assets/img/pictos/keywordStatus/status-pulse.svg' ) left 40% / 16px 16px no-repeat;      
      }
      smart-list-item:nth-of-type(4) .smart-list-item-label-element::before
      {
        mask: url( './../../../assets/img/pictos/keywordStatus/status-ignore.svg' ) left 40% / 16px 16px no-repeat;      
      }
    }
  }
}