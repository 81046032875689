.edito-calendar
{
  width: 100%;
  height: 100vh;
  padding: 0 8px 0 64px;
  box-sizing: border-box;

  .box1
  {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    color: var( --color-blue );
  }

  .box2
  {
    height: 72px;
    display: flex;
    align-items: center;
    background-color: white;

    .header
    {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h3{
        margin: 0;
        font-size: 1.50rem;
        font-weight: bold;
        color: var( --color-grey2 );
        line-height: 1.3rem;      
      }

      .header-left-container{
        display: flex;
        flex-flow: row;
        align-items: center;
        gap: 8px;
        padding: 16px 24px;
      }
    }
  }

  .box3
  {
    position: relative;

    .loader-container
    {
      position: absolute;
      height: 100%;
    }
  }
}