smart-date-time-picker
{
  --smart-font-size: 0.93rem;
  --smart-background: white;
  --smart-background-color: var( --color-blue );
  --smart-ui-state-border-hover: transparent;
  --smart-editor-label-padding: 10px;

  .smart-calendar-button
  {
    --smart-surface: white;
    --smart-surface-color: var( --color-grey2 );
    --smart-ui-state-hover: var( --color-grey1 );
    --smart-ui-state-active: white;
    --smart-ui-state-color-hover: var( --color-grey2 );
    --smart-ui-state-color-focus: var( --color-grey2 );
    --smart-ui-state-color-active: var( --color-grey2 );
    --smart-editor-addon-width: 30px;
    --smart-arrow-size: 12px;
    
    &:hover
    {
      --smart-ui-state-active: var( --color-grey1 );
    }
  }

  .smart-date-time-drop-down
  {
    --smart-editor-drop-down-height: 390px;
  }

  &.month{
    .smart-calendar-weeks:hover{
      background-color: var( --color-grey1 );
    }
  }
  
  &.week{
    .smart-calendar-week:hover{
      background-color: var( --color-grey1 );
    }   
  }
}