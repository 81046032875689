.filter-container,
.filters-groups-container
{
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 8px;  

  &:last-child{
    border-right: none;
  }

  .picto-title
  {
    display: flex;
    flex-flow: row;
    margin: 0 0 16px 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: var( --color-grey2 );
    
    svg{
      margin-right: 5px;
    }
  }

  smart-drop-down-list
  {
    --smart-editor-height: 32px;
    --smart-editor-label-padding: 8px;
    --smart-editor-addon-width: 30px;
    --smart-drop-down-list-default-width: 100%;
    --smart-arrow-size: 20px;  

    .smart-drop-down[bottom]
    {
      top: 28px;
    }
  }
}