smart-grid
{
  --smart-grid-default-width: 100%;
  --smart-grid-column-buttons-width: 40px;

  .smart-grid-header[smart-id="header"]
  {
    --smart-grid-header-height: 72px;
    --smart-background: transparent;
    --smart-surface: transparent;
    --smart-info: transparent;

    position: absolute;
    top: 0;
    right: 40px;
    width: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    smart-grid-toolbar
    {
      .smart-data-view-customize-button
      {
        --smart-border-top-right-radius: 50%;
        --smart-border-top-left-radius: 50%;
        --smart-border-bottom-right-radius: 50%;
        --smart-border-bottom-left-radius: 50%;

        width: 40px;
        height: 40px;
        padding: 0;   
        margin: 0;

        .smart-tool-label{
          display: none;
        }

        &.hidden{
          background-color: var( --smart-info );
          color: var( --smart-info-color );
        }

        &:hover{
          --smart-info: var( --color-grey3 );
        }

        &::after
        {
          content: '';
          position: relative;
          left: 0;
          width: 24px;
          height: 24px;
          mask: url( './../../../assets/img/pictos/sliders-horizontal.svg' ) center center / 24px 24px no-repeat;
          background-color: var( --color-grey2 );
        }
      }
    }
  }

  .smart-grid-content
  {
    .smart-grid-column-header
    {
      .smart-grid-column-header-cell-container{
        smart-grid-column
        {
          .smart-label.hidden{
            display: none;
          }
          .smart-grid-icon.column-icon::before 
          {
            content: ' ';
            width: 16px;
            height: 16px;
            mask: url( './../../../assets/img/pictos/arrows-out-simple.svg' ) left 40% / 16px 16px no-repeat;
            background-color: var( --color-grey2 );
          }
        }
      }
    }
  }

  smart-grid-cell[data-field='subitems_toggler']
  {
    .subitems-toggler-container
    {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 100%;

      .subitems-toggler
      {
        width: 16px;
        height: 100%;
        padding: 0 !important;
        margin: 0 !important;
        font-size: 1.4rem;
        font-weight: 100 !important;
        color: var( --color-grey2 );

        &:hover{
          color: var( --color-grey2 ) !important;
        }
      }
    }
  }
  smart-grid-row[expanded] smart-grid-cell[data-field='subitems_toggler'] .subitems-toggler-container .subitems-toggler
  {
    transform: rotate( 180deg );
  }

  smart-grid-cell[data-field='label']
  {
    .textlink-wrapper-text
    {
      cursor: pointer;

      &:hover{
        text-decoration: underline;
      }
    }
  }

  smart-grid-cell[data-field='snippets'] > div
  {
    overflow: visible;

    --smart-window-default-width: auto;
    --smart-window-default-height: 44px;
    --smart-window-min-width: 44px;
    --smart-window-header-height: 44px;
    
    .window-button.pictos-details,
    .window-button.pictos-details smart-button
    {
      width: 24px;
      height: 24px;
    }

    .window-button.pictos-details smart-window
    {
      &.top{
        top: 34px;
      }

      &.bottom{
        top: inherit;
        bottom: 34px;
      }
    }

    .window-button.pictos-details smart-window .smart-content
    {
      flex-flow: row;
      gap: 8px;
    }  
  }  

  smart-grid-cell[data-field='gsc_urls']
  {
    .textlink-wrapper
    {
      padding: 0;

      .textlink-container
      {
        justify-content: flex-start;
  
        .textlink-wrapper-text
        {
          width: auto;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
  
        span
        {
          height: 16px;        
          line-height: 16px;
  
          svg
          {
            border-radius: 50%;
            background-color: var( --color-orange );
            margin-right: 8px;
          }
        }

        .subitems-toggler
        {
          background-color: transparent;
          font-size: 0.875rem;
          color: var( --color-grey2 );
        }
      }
    }    
  }

  smart-grid-cell[data-field='status_action'] > div
  {
    overflow: visible;

    --smart-window-default-width: auto;
    --smart-window-default-height: 44px;
    --smart-window-min-width: 44px;
    --smart-window-header-height: 44px;
    
    .window-button.status-action,
    .window-button.status-action smart-button
    {
      width: 24px;
      height: 24px;
    }

    .window-button.status-action smart-window
    {
      height: auto;
      right: 0px;
      
      &.top{
        top: 36px;
        bottom: inherit;
      }

      &.bottom{
        top: inherit;
        bottom: 36px;
      }
    }

    .window-button.status-action smart-window .smart-content
    {
      padding: 0;
    }
  }

  smart-grid-cell[data-field='remove'],
  smart-grid-cell[data-field='resetPassword']
  {
    .window-button.remove-element smart-window,
    .window-button.reset-password smart-window
    {
      &.bottom{
        top: inherit !important;
        bottom: -8px;
      }
    }
  }

  smart-grid-cell[data-field='cat1'] > div,
  smart-grid-cell[data-field='cat2'] > div,
  smart-grid-cell[data-field='cat3'] > div,
  smart-grid-cell[data-field='role'] > div,
  smart-grid-cell[data-field='remove'] > div,
  smart-grid-cell[data-field='resetPassword'] > div,
  smart-grid-cell[data-field='status'] > div,
  smart-grid-cell[data-field='contributor'] > div,
  smart-grid-cell[data-field='pageType'] > div
  {
    overflow: visible;
  }

  smart-grid-column[data-field='volume_variation'],
  smart-grid-column[data-field='clicks_variation'],
  smart-grid-column[data-field='position_variation'],
  smart-grid-column[data-field='SEO1PixPos_variation'],
  smart-grid-column[data-field='SEORate_variation'],
  smart-grid-column[data-field='rate_variation']
  {
    .smart-label > span {
      display: none;
    }
  }

  smart-grid-column
  {
    .smart-grid-icon
    {
      &.smart-icon-help-circled
      {
        &:before
        {
          content: '';
          width: 16px;
          height: 16px;
          mask: url( './../../../assets/img/pictos/info.svg' ) left / 16px 16px no-repeat;
          background-color: var( --color-blue );
        }
      }
    }
  }
}