.pictures-wrapper
{
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 100%;
  gap: 8px;

  span
  {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;

    &.highlight{
      border-radius: 50%;
      background-color: var( --color-grey2 );
    }
  }

  .window-button .smart-button
  {
    font-size: 0.75rem;
  }
}

smart-grid-cell[data-field='volume_value'],
smart-grid-cell[data-field='rate_value'],
smart-grid-cell[data-field='position_value']
{
  .pictures-wrapper{
    justify-content: flex-end;
  }
}

smart-grid-cell[data-field='snippets']
{
  .pictures-wrapper{
    justify-content: left;
  }
}