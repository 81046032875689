.admin
{
  width: 100%;
  height: 100vh;
  padding: 0 8px 0 64px;
  box-sizing: border-box;

  .admin-box1
  {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    color: var( --color-blue );
  }

  .admin-box2
  {        
    height: 72px;
    background-color: var( --color-grey2 );
  }

  .admin-box3
  {
    position: relative;
    height: calc( 100% - 144px );

    .loader-container
    {
      position: absolute;
      height: 100%;
    }
  }
}