.smart-button-group
{
  --smart-font-size: 0.94rem;
  --smart-font-weight: 500;
  
  --smart-button-padding: 4px;

  --smart-border-top-left-radius: none;
  --smart-border-top-right-radius: none;
  --smart-border-bottom-left-radius: none;
  --smart-border-bottom-right-radius: none;

  --smart-background: var( --color-grey1 );
  --smart-background-color: var( --color-grey2 );

  --smart-ui-state-selected: var( --color-grey2 );
  --smart-ui-state-hover: var( --color-grey2 );
  --smart-ui-state-active: var( --color-grey2 );
  --smart-ui-state-focus: var( --color-grey2 );
  
  --smart-ui-state-color-selected: white;
  --smart-ui-state-color-hover: white;
  --smart-ui-state-color-active: white;
  --smart-ui-state-color-focus: white;

  .smart-button-group-items
  {
    .smart-button-group-item
    {
      width: 48px;
      height: 40px;

      svg{
        stroke: var( --color-grey2 );
      }

      &:hover,
      &[active]{
        svg{
          stroke: white;
        }
      }
    }
  }

  &.column{
    .smart-button-group-items{      
      flex-flow: column;      
    }
  }

  &.empty
  {
    --smart-background: transparent;
    --smart-background-color: var( --color-grey2 );
    
    --smart-ui-state-selected: none;
    --smart-ui-state-hover: none;
    --smart-ui-state-active: none;
    --smart-ui-state-focus: none;
    
    --smart-ui-state-color-selected: var( --color-grey2 );
    --smart-ui-state-color-hover: var( --color-grey2 );
    --smart-ui-state-color-active: var( --color-grey2 );
    --smart-ui-state-color-focus: var( --color-grey2 );

    --smart-border: transparent;
    --smart-ui-state-border-selected: transparent;
    --smart-ui-state-border-hover: transparent;
    --smart-ui-state-border-active: transparent;
    --smart-ui-state-border-focus: transparent;

    .smart-button-group-items
    {
      .smart-button-group-item
      {
        border: solid 1px transparent;

        &:hover,
        &[active]{
          border-bottom: solid 1px var( --color-grey2 );
        }
      }
    }
  }

  &.column.empty{
    .smart-button-group-items{
      .smart-button-group-item
      {
        &:hover,
        &[active]{
          border-bottom-color: transparent;
          border-left-color: var( --color-grey2 );
        }
      }
    }
  }
}