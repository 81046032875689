smart-calendar.smart-calendar
{
  --smart-calendar-title-height: 8px;

  padding: 16px;
  font-size: 0.9375rem;

  .smart-calendar-date-element:focus,
  .smart-calendar-date-view-container .smart-calendar-cell[focus]
  {
    border-color: transparent;
  }

  .smart-calendar-title
  {
    display: none;
  }

  .smart-calendar-container
  {
    .smart-calendar-header
    {
      --smart-font-size : 0.94rem;
      --smart-border: transparent;
      --smart-surface: white;
      --smart-surface-color: var( --color-blue );
      --smart-ui-state-color-hover: var( --color-grey2 );

      justify-content: space-around;

      .smart-calendar-button,
      .smart-calendar-month-element,
      .smart-calendar-year-element
      {
        border: solid 1px var( --color-grey3 );
        height: 32px;
      }
  
      .smart-calendar-button
      {        
        border-radius: 0;  
        font-size: 1rem;
        font-weight: bold;
        color: var( --color-grey2 );

        .smart-arrow-left:after,
        .smart-arrow-right:after
        {
          --smart-arrow-size: 20px;

          font-weight: bold;      
        }
      }
    }
    
    .smart-calendar-body
    {
      --smart-primary: var( --color-blue );
      --smart-calendar-footer-height: 0;

      .smart-calendar-months-container,
      .smart-calendar-next-months-container
      {
        align-items: start;
        margin-top: 15px;

        .smart-calendar-month
        {
          margin: 0;
          width: 100%;
          
          .smart-calendar-week-titles
          {
            --smart-calendar-week-title-text-transform: capitalize;
    
            color: var( --color-grey2 );      
            justify-content: space-around;  
          }
    
          .smart-calendar-weeks
          {
            .smart-calendar-week
            {
              --smart-calendar-cell-spacing: 0;

              color: var( --color-blue );              
              justify-content: space-around;  
              margin: 3px 0;
            }
  
            .smart-calendar-cell[today]
            {
              --smart-secondary: var( --color-grey2 );
            }
    
            .smart-calendar-cell[other-month]
            {
              --smart-background-color: var( --color-grey2 );
            }
          }
        }            
      }
    }          
  }  
}