// Normalize css
@import-normalize;

// smart elements default css
@import 'smart-webcomponents-react/source/styles/smart.default.css';
@import './assets/css/smart-custom.scss';
@import './assets/css/smart-button.scss';
@import './assets/css/smart-button-group.scss';
@import './assets/css/smart-radio-button.scss';
@import './assets/css/smart-drop-down-button.scss';
@import './assets/css/smart-drop-down-list.scss';
@import './assets/css/smart-grid.scss';
@import './assets/css/smart-form.scss';
@import './assets/css/smart-chart.scss';
@import './assets/css/smart-tooltip.scss';
@import './assets/css/smart-date-time-picker.scss';
@import './assets/css/smart-calendar.scss';
@import './assets/css/smart-window.scss';
@import './assets/css/smart-scroll-viewer.scss';
@import './assets/css/smart-slider.scss';
@import './assets/css/smart-check-box.scss';
@import './assets/css/smart-input.scss';
@import './assets/css/smart-customize-panel.scss';
@import './assets/css/smart-scroll-bar.scss';
@import './assets/css/smart-list-item.scss';
@import './assets/css/smart-switchbutton.scss';
@import './assets/css/smart-filter-panel.scss';
@import './assets/css/smart-tree.scss';

// import fonts
@import './assets/css/fonts.scss';

// import colors
@import './assets/css/colors.scss';

html {
  // height: 100%;
}

body
{
  // height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  color: var( --color-blue );
}

/** Titles */
h1, h2{  
  font-size: 1.63rem;
}

h1, h2, h3, h4, h5, h6{
  font-family: PPNeueMontreal;
  font-weight: normal;
}

p{
  margin: 0;
}

ul, li{
  margin: 0;
  padding: 0;
}

button{
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

input
{
  border: none;
  height: 30px;
  width: 100%;
  padding: 8px;
  color: var( --color-grey2 );
  font-size: 0.875rem;
  outline: none;
}