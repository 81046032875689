.instance-keywords
{
  width: 100%;  
  padding: 0 8px 0 64px;
  box-sizing: border-box;
  
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 72px 72px 300px auto;
  
  transition: grid-template-rows 500ms;
  
  .box1,
  .box2
  {    
    position: sticky;      
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;    
  }

  .box2,
  .box3
  {
    background-color: var( --color-white );
  }

  .box2,
  .box4
  {
    .loader-container
    {
      position: absolute;
      z-index: 21;
      justify-content: start;

      .indicator{
        margin-top: 150px;
      }
    }  
  }

  .box1
  {
    top: 0;
    z-index: 50;
    background-color: var( --color-grey1 );
    padding: 16px 24px;

    .period-infos-wrapper
    {
      display: flex;
      flex-flow: row;
      gap: 16px;
    }
  }

  .box2
  {
    top: 72px;
    z-index: 40;

    .header
    {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h3{
        margin: 0;
        font-size: 1.50rem;
        font-weight: bold;
        color: var( --color-grey2 );
        line-height: 1.3rem;      
      }

      .header-left-container,
      .header-right-container{
        display: flex;
        flex-flow: row;
        align-items: center;
        gap: 8px;
        padding: 16px 24px;
      }

      .header-left-container
      {
        .display-mode-selector
        {
          margin: 0 42px;

          .smart-button-group-items{
            .smart-button-group-item{
              width: 40px;
            }
          }
        }
      }

      .header-right-container
      {
        smart-button
        {
          width: 40px;
          height: 40px;
        }

        .window-button.filters smart-window,
        .window-button.keywords-groups smart-window
        {
          height: auto !important;
          left: auto !important;          
          top: 56px !important;
        }

        .window-button.filters smart-window        
        { 
          right: -152px !important;
        }

        .window-button.keywords-groups smart-window
        {
          width: auto !important;
          right: -104px !important;
        }
      }
    }

    .window-button
    {
      height: 100%;
      
      smart-button.mass-action
      {
        --smart-border-top-left-radius: 0;
        --smart-border-top-right-radius: 0;
        --smart-border-bottom-left-radius: 0;
        --smart-border-bottom-right-radius: 0;
  
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
  
        .checkboxes-sum
        {
          width: 30px;
        }
      }

      #mass-action-window
      {
        width: 264px;
        height: auto;
        top: 72px;
        left: -184px;

        .smart-content
        {
          padding: 0;

          .expected-url-container .best-url,
          .expected-url-container .other-url
          {
            height: 66px;
          }
        }
      }
    }
  }

  .box3
  {
    display: grid;
    grid-template-rows: 72px auto;

    .header,
    .chart-displayer-wrapper
    {
      display: grid;
      grid-template-columns: 3fr 10fr 1fr;
    }

    .header
    {
      align-items: center;
      background-color: var( --color-grey1 );

      .range-granularity{
        margin-left: 50px;
      }

      .left-col
      {
        text-align: right;

        smart-radio-button{
          font-size: 0.8125rem !important;
        }
      }
      
      .right-col{
        text-align: center;
      }        
    }

    .highlight-granularity,
    .highlight-selector
    {
      display: none;
    }
    
  }

  .box4
  {
    position: relative;
    display: flex;
    flex-flow: column;
  }

  &.full-chart
  {
    height: 100%;
    grid-template-rows: 72px 72px calc( 100vh - 144px ) 0 !important;
    transition: 500ms;

    .box4{
      opacity: 0;
      transition: opacity 500ms;
    }
  }

  &.full-grid
  {
    grid-template-rows: 72px 72px 0 auto !important;
    transition: 500ms;

    .box3{
      opacity: 0;
      transition: opacity 500ms;
    }
  }
}  
