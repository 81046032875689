.instance-market
{
  width: 100%;
  padding: 0 8px 0 64px;
  box-sizing: border-box;
  
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 72px 72px auto;
  
  transition: grid-template-columns 500ms;

  .box1,
  .box2
  {    
    grid-column-start: 1;
    grid-column-end: 3;

    position: sticky;      
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;    
  }

  .box2,
  .box3{
    background-color: var( --color-white );
  }

  .box1
  {
    top: 0;
    z-index: 50;
    background-color: var( --color-grey1 );
    padding: 16px 24px;

    .period-infos-wrapper
    {
      display: flex;
      flex-flow: row;
      gap: 16px;
    }
  }

  .box2
  {
    top: 72px;
    z-index: 40;

    .header
    {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h3{
        margin: 0;
        font-size: 1.50rem;
        font-weight: bold;
        color: var( --color-grey2 );
        line-height: 1.3rem;      
      }

      .header-left-container,
      .header-right-container{
        display: flex;
        flex-flow: row;
        align-items: center;
        gap: 8px;
        padding: 16px 24px;
      }

      .header-left-container
      {
        .display-mode-selector
        {
          margin: 0 42px;

          .smart-button-group-items{
            .smart-button-group-item{
              width: 40px;
            }
          }
        }
      }

      .header-right-container
      {
        smart-button
        {
          width: 40px;
          height: 40px;
        }

        .window-button.filters smart-window,
        .window-button.keywords-groups smart-window
        {
          height: auto !important;
          left: auto !important;          
          top: 56px !important;
        }

        .window-button.filters smart-window        
        { 
          right: -152px !important;
        }

        .window-button.keywords-groups smart-window
        {
          width: auto !important;
          right: -104px !important;
        }
      }
    }

    .next-page
    {
      width: 80px;
      height: 100%;

      smart-button
      {
        --smart-border-top-left-radius: 0;
        --smart-border-top-right-radius: 0;
        --smart-border-bottom-left-radius: 0;
        --smart-border-bottom-right-radius: 0;

        display: flex;
        align-items: center;
        justify-content: space-around;

        .checkboxes-sum
        {
          width: 30px;
        }
      }
    }
  }

  .box3
  {
    position: relative;
    height: auto;
    margin-right: 4px;
    opacity: 1;
    transition: opacity 1s;
    
    .header,
    .widget-wrapper
    {
      display: grid;
      grid-template-columns: 4fr 10fr 1fr;
    }

    .header
    {
      position: sticky;
      top: 144px;
      z-index: 30;
      height: 72px;
      align-items: center;
      background-color: var( --color-grey1 );

      .range-granularity{
        margin-left: 30px;
      }

      .left-col
      {
        text-align: right;

        smart-radio-button{
          font-size: 0.8125rem !important;
        }
      }
      
      .right-col{
        text-align: center;
      }   
    }

    .widget-wrapper
    {
      margin: 30px 0;

      &.highlight-granularity
      {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        margin-top: 0;
        
        .range-granularity
        {
          grid-column-start: 2;
                
          margin-left: 30px;
        }
      }

      .widget
      {
        grid-column-start: 1;
        grid-column-end: 3;

        display: grid;
        grid-template-columns: 4fr 10fr;
      }

      .button-group-graph-select
      {
        --smart-button-group-default-width: fit-content;
        --smart-button-group-default-height: fit-content;

        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-around;

        .smart-button-group{
          .smart-button-group-items{
            .smart-button-group-item{
              width: 40px;
              height: 40px;
            }
          }
        }

        .split-button,
        .grouped-button
        {
          width: 24px;
          height: 24px;
        }
        .split-button
        {
          background: url( './../../assets/img/pictos/button-split-view.svg' ) no-repeat;
        }
        .grouped-button
        {
          background: url( './../../assets/img/pictos/button-grouped-view.svg' ) no-repeat;
        }
      }
    }
  }

  .box4
  {
    height: auto;
    position: relative;
    margin-left: 4px;
    opacity: 1;
    transition: opacity 1s;

    smart-grid
    {
      position: sticky;
      top: 144px;
      z-index: 20;
    }

    .loader-container
    {
      position: absolute;
      z-index: 21;
    }
  }

  &.full-chart
  {
    grid-template-columns: 100% 0;
    transition: 500ms;

    .box3{
      margin-right: 0;

      .header,
      .widget-wrapper
      {
        grid-template-columns: 3fr 11fr 1fr;

        .widget{
          grid-template-columns: 3fr 11fr;
        }
      }
      
    }
    .box4{
      opacity: 0;
      transition: opacity 500ms;
    }
  }

  &.full-grid
  {
    grid-template-columns: 0 100%;        
    transition: 500ms;

    .box4{
      margin-left: 0;
    }

    .box3{
      opacity: 0;
      transition: opacity 500ms;
    }
  }
}  
