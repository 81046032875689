.edito-calendar-container
{
  position: relative;
  padding: 32px 24px;
  min-height: 282px;

  .editor-calendar-recorded
  {
    position: absolute;
    padding: 32px 24px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var( --color-grey3 );
    z-index: 1;

    .confirm-container{
      smart-button
      {
        &.fill
        {
          width: 180px !important;
        }      
      }
    }
  }

  .input-url-container
  {
    display: flex;
    align-items: center;
    gap: 8px;

    button{
      background-color: var( --color-grey3 );
    }

    .smart-input-prefix,
    .smart-input-suffix
    {
      display: none;
    }
  }

  .confirm-container
  {
    padding-right: 32px;
    margin-top: 32px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 15px;

    smart-button
    {
      justify-content: center;
      font-weight: 400;

      &.empty,
      &.fill
      {
        height: 32px !important;
      }
      &.empty{
        width: 117px !important;          
      }
      &.fill{
        width: 117px !important;
      }
    }
  }
}