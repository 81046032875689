smart-grid.smart-grid.grid-edito-calendar
{
  min-height: calc( 100vh - 200px ) !important;

  .smart-grid-column-header
  {
    smart-grid-column[data-field="keyword"]{
      padding-left: 17px;
    }
  }

  .smart-grid-row-container
  {
    smart-grid-row
    {
      font-size: 0.9375rem;
      
      smart-grid-cell[data-field="keyword"]{
        padding-left: 24px;
      }
  
      smart-grid-cell[data-field="pageType"],
      smart-grid-cell[data-field="status"],
      smart-grid-cell[data-field="contributor"]
      {
        .smart-token
        {
          display: flex;
          align-items: center;
          font-size: 0.9375rem;
          color: var( --color-blue );
          height: 24px;
          align-items: center;
        }
      }
  
      smart-grid-cell[data-field="pageType"]
      {
        smart-drop-down-list
        {      
          smart-list-item .smart-list-item-label-element,
          .smart-token
          {
            &::before
            {
              content: '';
              width: 18px;
              height: 18px;
              margin-right: 5px;
              background-color: var( --color-blue );
            }
          }
          
          &[selected-indexes="[0]"] .smart-token::before{
            mask: url( './../../../../assets/img/pictos/pageType/house.svg' ) center / 18px 18px no-repeat; 
          }
          &[selected-indexes="[1]"] .smart-token::before{
            mask: url( './../../../../assets/img/pictos/pageType/list-magnifying-glass.svg' ) center / 18px 18px no-repeat; 
          }
          &[selected-indexes="[2]"] .smart-token::before{
            mask: url( './../../../../assets/img/pictos/pageType/tag.svg' ) center / 18px 18px no-repeat; 
          }
          &[selected-indexes="[3]"] .smart-token::before{
            mask: url( './../../../../assets/img/pictos/pageType/newspaper-clipping.svg' ) center / 18px 18px no-repeat; 
          }
  
          smart-list-item:nth-of-type(1) .smart-list-item-label-element::before{
            mask: url( './../../../../assets/img/pictos/pageType/house.svg' ) center / 18px 18px no-repeat; 
          }
          smart-list-item:nth-of-type(2) .smart-list-item-label-element::before{
            mask: url( './../../../../assets/img/pictos/pageType/list-magnifying-glass.svg' ) center / 18px 18px no-repeat; 
          }
          smart-list-item:nth-of-type(3) .smart-list-item-label-element::before{
            mask: url( './../../../../assets/img/pictos/pageType/tag.svg' ) center / 18px 18px no-repeat; 
          }
          smart-list-item:nth-of-type(4) .smart-list-item-label-element::before{
            mask: url( './../../../../assets/img/pictos/pageType/newspaper-clipping.svg' ) center / 18px 18px no-repeat; 
          }
        }
      }
  
      smart-grid-cell[data-field="status"]
      {
        smart-drop-down-list
        {      
          smart-list-item .smart-list-item-label-element,
          .smart-token
          {
            &::before
            {
              content: '';              
              width: 24px;
              height: 24px;
              margin-right: 5px;
              border-radius: 50%;
            }
          }
  
          &[selected-indexes="[0]"] .smart-token::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/pencil-simple.svg' ) center / 18px 18px no-repeat var( --color-grey2 );
          }
          &[selected-indexes="[1]"] .smart-token::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/clock.svg' ) center / 18px 18px no-repeat var( --color-blue2 );
          }
          &[selected-indexes="[2]"] .smart-token::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/check-square-offset.svg' ) center / 18px 18px no-repeat var( --color-orange );
          }
          &[selected-indexes="[3]"] .smart-token::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/globe.svg' ) center / 18px 18px no-repeat var( --color-blue );
          }
          &[selected-indexes="[4]"] .smart-token::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/trash-simple.svg' ) center / 18px 18px no-repeat var( --color-yellow );
          }
          
          smart-list-item:nth-of-type(1) .smart-list-item-label-element::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/pencil-simple.svg' ) center / 16px 16px no-repeat var( --color-grey2 ); 
          }
          smart-list-item:nth-of-type(2) .smart-list-item-label-element::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/clock.svg' ) center / 16px 16px no-repeat var( --color-blue2 );
          }
          smart-list-item:nth-of-type(3) .smart-list-item-label-element::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/check-square-offset.svg' ) center / 16px 16px no-repeat var( --color-orange );      
          }
          smart-list-item:nth-of-type(4) .smart-list-item-label-element::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/globe.svg' ) center / 16px 16px no-repeat var( --color-blue );      
          }
          smart-list-item:nth-of-type(5) .smart-list-item-label-element::before
          {
            background: url( './../../../../assets/img/pictos/editoStatus/trash-simple.svg' ) center / 16px 16px no-repeat var( --color-yellow );      
          }
        }
      } 
      
      smart-grid-cell[data-field="volume"],
      smart-grid-cell[data-field="position"],
      smart-grid-cell[data-field="clicks"]
      {
        .comparevalues-wrapper{
          justify-content: center;
        }
      }

      smart-grid-cell[data-field="publicationDate"]
      {
        .smart-grid-cell-template
        {
          margin-left: 8px;

          span{
            color: var( --color-grey2 );
          }
        }
      }
    }
  }
}