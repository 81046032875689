smart-filter-panel.smart-filter-panel
{
  --smart-border: var( --color-grey3 );
  --smart-primary: var( --color-blue );
  --smart-background: white;
  --smart-surface: white;
  --smart-surface-color: var( --color-blue );
  --smart-ui-state-active: white;
  --smart-ui-state-color-hover: var( --color-blue );
  --smart-ui-state-color-active: var( --color-blue );
  --smart-ui-state-border-hover: var( --color-blue );
  --smart-ui-state-border-active: var( --color-blue );  

  --smart-editor-height: 100%;
  
  .smart-filter-panel-input
  {
    --smart-editor-height: 30px;
  }
}