.granularity-selector
{
  .smart-button-group
  {
    font-size: 0.75rem;
    text-transform: none;

    .smart-button-group-items
    {
      gap: 4px;
      
      .smart-button-group-item
      {
        height: 24px !important;
        width: 70px;
  
        &:hover,
        &[active]{
          border-left-color: transparent !important;
          border-right-color: var( --color-grey2 ) !important;
        }
      }
    }
  }
}