#filter-range-volume-dropdown-menu-evol,
#filter-range-clicks-dropdown-menu-evol,
#filter-range-impressions-dropdown-menu-evol,
#filter-range-ctr-dropdown-menu-evol,
#filter-range-ranked-rate-dropdown-menu-evol,
#filter-range-position-dropdown-menu-evol,
#filter-range-position-dropdown-menu-move
{
  smart-list-item{
    .smart-list-item-label-element
    {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-start;

      &::before{
        width: 16px;
        height: 16px;
        content: '';
        margin-right: 5px;
      }
    }
  }
}

#filter-range-volume-dropdown-menu-evol,
#filter-range-clicks-dropdown-menu-evol,
#filter-range-impressions-dropdown-menu-evol,
#filter-range-ctr-dropdown-menu-evol,
#filter-range-ranked-rate-dropdown-menu-evol,
#filter-range-position-dropdown-menu-evol
{
  smart-list-item:nth-of-type(1) .smart-list-item-label-element::before
  {
    mask: url( './../../assets/img/pictos/arrow-up-right.svg' ) left 40% / 16px 16px no-repeat;
    background-color: var( --color-blue );
  }
  smart-list-item:nth-of-type(2) .smart-list-item-label-element::before
  {
    mask: url( './../../assets/img/pictos/arrow-right.svg' ) left 40% / 16px 16px no-repeat;
    background-color: var( --color-yellow );
  }
  smart-list-item:nth-of-type(3) .smart-list-item-label-element::before
  {
    mask: url( './../../assets/img/pictos/arrow-down-right.svg' ) left 40% / 16px 16px no-repeat;
    background-color: var( --color-orange );
  }
}

#filter-range-position-dropdown-menu-move
{
  smart-list-item:nth-of-type(1) .smart-list-item-label-element::before
  {
    mask: url( './../../assets/img/pictos/sign-in.svg' ) left 40% / 16px 16px no-repeat;
    background-color: var( --color-blue );
  }
  smart-list-item:nth-of-type(2) .smart-list-item-label-element::before
  {
    mask: url( './../../assets/img/pictos/sign-out.svg' ) left 40% / 16px 16px no-repeat;
    background-color: var( --color-orange );
  }
}