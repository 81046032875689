// Colors
:root{
  --color-grey1: #f2f5f6;
  --color-grey2: #6b8799;
  --color-grey3: #e6eaee;

  --color-blue: #0a3856;
  --color-blue-opacity: #476980;
  --color-blue2: #1289A7;
  
  --color-orange: #e9540d;
  --color-orange-opacity: #ee7e49;
  
  --color-yellow: #fcc02d; 

  --color-white: #ffffff;

  --color-invalid: #ffcccc;

  --snippet-color-paa: #FFC312;
  --snippet-color-popular-products: #C4E538;
  --snippet-color-images: #12CBC4;
  --snippet-color-videos: #FDA7DF;
  --snippet-color-knowledge-panel: #ED4C67;
  --snippet-color-local-results: #F79F1F;
  --snippet-color-recipes: #A3CB38;
  --snippet-color-map: #1289A7;
  --snippet-color-carousel: #D980FA;
}